const theme = {
    background: {
        primary: {
            default: 'var(--primary-gradient)',
            hover: 'var(--dark-blue-gradient)',
        },
        secondary: {
            default: 'var(--white)',
            hover: 'var(--secondary-background)',
        },
        link: {
            default: 'transparent',
            hover: 'transparent',
        },
    },
    measurements: {
        big: {
            height: '40px',
        },
        medium: {
            height: '34px',
        },
        small: {
            height: '26px',
        },
        xs: {
            height: '20px',
        },
    },
    padding: {
        big: '12px 21px',
        medium: '8px 20px',
        small: '8px 16px',
        xs: 'var(--indent-s) var(--indent-m)',
    },
    borderRadius: {
        rounded: 'var(--border-radius-m)',
        circled: '50%',
    },
    fontSize: {
        big: '0.95rem',
        medium: '0.95rem',
        small: '0.9rem',
        xs: '0.9rem',
    },
    border: {
        primary: 'none',
        secondary: 'inset 0 0 0 1px #ccc',
    },
    boxShadow: {
        default: '0 4px 4px rgba(33, 33, 33, 0.1)',
        hover: '0 4px 8px rgba(33, 33, 33, 0.2)',
    },
    color: {
        primary: '#fff',
        secondary: '#333',
        link: 'var(--link-color)',
    },
};

export default theme;
